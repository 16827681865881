// FIX ticket #1058 manually because url is not bad with ckfinder (old page from migrating drupal 6)
function replaceSrcCkFinder() {
  jQuery('img[src*="Biblioth%C3%A8que"]').each(function () {
    var src = jQuery(this).attr("src");
    var newSrc = src.replace("Biblioth%C3%A8que", "Biblioth%23U00e8que");
    jQuery(this).attr("src", newSrc);
  });
}

// Evolution ticket #980 add "/en" to link in english home page bloc actu / event
function replaceLinkEn() {
  jQuery(
    ".i18n-en .home-content #block-views-block-list-news-event-block .slick-slider a"
  ).attr("href", function (i, href) {
    return "/en/" + href;
  });
  jQuery(
    ".i18n-en .home-content #block-views-block-list-news-event-block .navigation a"
  ).attr("href", function (i, href) {
    return "/en" + href;
  });
}

jQuery(document).ajaxComplete(function () {
  replaceSrcCkFinder();
  replaceLinkEn();
});
jQuery(document).ajaxSuccess(function () {
  replaceSrcCkFinder();
  replaceLinkEn();
});

jQuery(document).ready(function ($) {
  //"use strict";

  // Launch foundation framework
  $(document).foundation();

  // Fix too much animation
  var animated = false;

  // Calcul window width form responsive
  var windowWidth = $(window).width();
  $(window).resize(function () {
    windowWidth = $(window).width();
  });

  replaceSrcCkFinder();
  replaceLinkEn();
  // Launch lightgallery framework
  if ($(".lightgallery").length) {
    $(".lightgallery").lightGallery();

    /* -- If lightgallery inside a slick slider ------------------- */
    var linkGallery = $("a.open-lightgallery", ".slick-slider");
    linkGallery.click(function (event) {
      event.preventDefault();
    });

    var start, end, timebetween;
    linkGallery.mousedown(function () {
      start = new Date();
    });

    linkGallery.mouseup(function (event) {
      end = new Date();
      timebetween = end - start;

      if (timebetween <= 100) {
        var target = event.currentTarget.href.replace(/http:|https:/gi, "");
        $(this)
          .parents(".slick-slider")
          .next(".lightgallery")
          .children('a[href*="' + target + '"]')
          .click();
      }
    });
    /* -- End if lightgallery inside a slick slider --------------- */
  }

  // Launch plyr video player
  if ($(".plyr-player").length) {
    var instances = plyr.setup(".plyr-player");
    instances.forEach(function (instance, index) {
      instance.on("ready", function () {
        instance.getContainer().setAttribute("id", "plyr-player-" + index);
        instance.id = "plyr-player-" + index;
      });
    });
  }

  // Pause on plyr video player when close modale
  $(window).on("closed.zf.reveal", function (event) {
    var target = $(event.target).find(".plyr").attr("id");
    instances.forEach(function (instance) {
      if (target === instance.id) {
        instance.pause();
      }
    });
  });

  // Block home header.
  if ($("#block-enpc-blocks-direct-access").length) {
    // Load the linked page for the given taxo
    $("#choice").change(function () {
      var tid = $("#choice option:selected").val();

      if (tid != "") {
        $.get(
          "/load/direct-access/" + tid,
          function (data) {
            if (data.status == "OK") {
              $("#target").empty();
              $(data.html).appendTo($("#target"));
              $("#target").removeAttr("disabled");
            } else {
              $("#target").empty();
              $(data.html).appendTo($("#target"));
              $("#target").attr("disabled", "disabled");
            }
          },
          "json"
        );
      }
    });

    // Redirect on the choosen page
    $("#valid-direct-access").click(function () {
      var destUrl = $("#target option:selected").val();
      if (destUrl != "") {
        if (!destUrl.match(/^http/)) {
          window.location.href = baseUrl + destUrl;
        } else {
          window.open(destUrl, "_blank");
        }
      }
    });
  }

  if ($("#block-enpc-blocks-home-map").length) {
    // @Leo: Please could you contextualize to not impact others features please ???
    $("#block-enpc-blocks-home-map select.form-select").change(function () {
      var destUrl = $(this).children("option:selected").val();
      if (destUrl != "") {
        window.location.href = baseUrl + destUrl;
      }
    });
  }

  // Node ajax load
  if ($("ul.page-navtabs-list").length) {
    $("ul.page-navtabs-list li a").click(function (event) {
      event.preventDefault();

      $("ul.page-navtabs-list li.active").removeClass("active");
      $(this).parent().addClass("active");

      $(".page-navtabs-content").load(
        "/load/content/" + $(this).data("nid"),
        function () {
          $.loadSlider();
        }
      );
    });
  }

  // Common advanced search
  if ($("#views-exposed-form-advanced-search-page").length) {
    $(
      "#views-exposed-form-advanced-search-page #edit-submit-advanced-search"
    ).click(function () {
      $("#edit-body-value").val($("#edit-title").val());
    });
  }
  // News & events serach
  if ($("#views-exposed-form-news-events-search-page").length) {
    $("#edit-submit-news-events-search").click(function () {
      $("#edit-body-value").val($("#edit-title").val());
      $('input[name="field_publish_date_value2[value][date]"]').val(
        $('input[name="field_publish_date_value[value][date]"]').val()
      );
    });
  }

  // Block search
  if ($("#search-block-form").length) {
    $("#search-block-form").on("submit", function () {
      if ($("#search-block-form-input").val().trim() == "") {
        return false;
      }
      $("#body_value").val($("#search-block-form-input").val());
    });
  }

  // Page teachers list
  if ($("#views-exposed-form-teachers-list-page").length) {
    $("#edit-thematic").change(function () {
      $("#views-exposed-form-teachers-list-page").submit();
    });
  }

  /* -- MENU ANIMATION ON MOBILE ----------------------------------------- */
  $("button.open-menu", "header#header").click(function () {
    if (!animated) {
      animated = true;

      var mainMenu = $(".action .wrapper-menu-mobile", "header#header");

      if (mainMenu.hasClass("open")) {
        setTimeout(function () {
          $("body").removeClass("no-scroll");
        }, 200);
      } else {
        $("body").addClass("no-scroll");
      }

      $(this).toggleClass("open");
      mainMenu.toggleClass("open");

      setTimeout(function () {
        $("nav#main-menu .sub-menu.open").removeClass("open");

        animated = false;
      }, 200);
    }
  });

  function openSubNav($this) {
    var $subMenu = $this.siblings(".sub-menu");
    if ($subMenu.find(".action-mobile") && !animated) {
      animated = true;

      $subMenu.toggleClass("open");

      //console.log($subMenu.height());

      setTimeout(function () {
        animated = false;
      }, 200);
    }
  }

  if (windowWidth < 1024) {
    $(
      "nav#main-menu ul > li > a, nav#main-menu ul > li > span",
      "header#header"
    ).click(function (event) {
      event.preventDefault();

      if (
        $(this).siblings(".sub-menu").length &&
        !$(this).siblings(".sub-menu").children("ul.menu").children("li.hide")
          .length
      ) {
        openSubNav($(this));
      } else {
        if ($(this).attr("href").length) {
          window.location = $(this).attr("href");
        }
      }
    });
  }

  $("nav#main-menu .action-mobile > .close-sub-menu", "header#header").click(
    function () {
      $(this).closest(".sub-menu.open").removeClass("open");
    }
  );

  $(
    "nav#main-menu .action-mobile > .close-all-sub-menu",
    "header#header"
  ).click(function () {
    $(this).parents(".sub-menu.open").removeClass("open");
  });

  /* -- END MENU ANIMATION ON MOBILE ------------------------------------- */

  /* -- POSITION ON DATA KEY MASTERHEAD AND MARGIN TOP ------------------- */
  function data4TopAndMargin() {
    var data2Height = $(".data-2", ".master-head").outerHeight(),
      data3Height = $(".data-3", ".master-head").outerHeight();

    $(".biblio-search-school")
      .css("margin-top", data2Height + 20)
      .find(".master-head .data-4")
      .css("top", data3Height);
  }

  if ($(".master-head").length) {
    if (windowWidth >= 1024) {
      data4TopAndMargin();
    }

    $(window).resize(function () {
      setTimeout(function () {
        if (windowWidth >= 1024) {
          data4TopAndMargin();
        } else {
          $(".biblio-search-school")
            .css("margin-top", "")
            .find(".master-head .data-4")
            .css("top", "");
        }
      }, 100);
    });
  }
  /* -- END POSITION ON DATA KEY TEMPLATE 3 ------------------------------ */

  /* -- CHANGE TAB ON MASTER LIST MOBILE --------------------------------- */
  function changeTabs($selectListTabs) {
    var mastSelect = $selectListTabs.val();

    $(".tab.active", ".list-tabs").removeClass("active");
    $("#" + mastSelect).addClass("active");
  }

  if ($(".list-tabs").length && $(".list-tabs:visible")) {
    var $selectListTabs = $("select.form-select", ".select-list-tabs");

    changeTabs($selectListTabs);

    $selectListTabs.change(function () {
      changeTabs($(this));
    });
  }
  /* -- END CHANGE TAB ON MASTER LIST MOBILE ----------------------------- */

  /* -- HOME SAME HEIGHT VIDEOS AND SOCIALS ------------------------------ */
  function homeSameHeightVideosSocials(socials) {
    var heightImageSlider = $(
      ".slick-track .image",
      "#block-views-block-list-video-block"
    ).height();
    $(".slick-arrow", "#block-views-block-list-video-block").css(
      "top",
      heightImageSlider - 60
    );

    if (socials) {
      $(
        ".content.tabs-content",
        "#block-social-media-list-social-media-tl-block"
      ).height(heightImageSlider);
    }
  }

  if (
    $("#block-views-block-list-video-block").length &&
    $("#block-social-media-list-social-media-tl-block").length
  ) {
    setTimeout(function () {
      if (windowWidth >= 1024) {
        homeSameHeightVideosSocials(true);
      } else if (1024 > windowWidth && windowWidth >= 640) {
        homeSameHeightVideosSocials(false);
      }
    }, 200);

    $(window).resize(function () {
      setTimeout(function () {
        if (windowWidth >= 1024) {
          homeSameHeightVideosSocials(true);
        } else {
          $(
            ".content.tabs-content",
            "#block-social-media-list-social-media-tl-block"
          ).height("");
        }

        if (1024 > windowWidth && windowWidth >= 640) {
          homeSameHeightVideosSocials(false);
        }
      }, 200);
    });
  }
  /* -- END HOME SAME HEIGHT VIDEOS AND SOCIALS -------------------------- */

  /* -- BIBLIO SAME HEIGHT VIDEOS AND SOCIALS ---------------------------- */
  function biblioSameHeightVideosSocials(socials) {
    var heightImage = $(".image", "#block-enpc-blocks-image-link").height();

    if (socials) {
      $(
        ".content.tabs-content",
        "#block-social-media-list-social-media-tl-block-2"
      ).height(heightImage);
    }
  }

  if (
    $("#block-enpc-blocks-image-link").length &&
    $("#block-social-media-list-social-media-tl-block-2").length
  ) {
    setTimeout(function () {
      if (windowWidth >= 1024) {
        biblioSameHeightVideosSocials(true);
      } else if (1024 > windowWidth && windowWidth >= 640) {
        biblioSameHeightVideosSocials(false);
      }
    }, 200);

    $(window).resize(function () {
      setTimeout(function () {
        if (windowWidth >= 1024) {
          biblioSameHeightVideosSocials(true);
        } else {
          $(
            ".content.tabs-content",
            "#block-social-media-list-social-media-tl-block-2"
          ).height("");
        }

        if (1024 > windowWidth && windowWidth >= 640) {
          biblioSameHeightVideosSocials(false);
        }
      }, 200);
    });
  }
  /* -- END HOME SAME HEIGHT VIDEOS AND SOCIALS -------------------------- */

  /* -- SIZE NEWS AND EVENTS --------------------------------------------- */
  if ($("#block-views-block-list-news-event-block").length) {
    var $newEmpty =
        $.trim(
          $(
            ".view-block-list-news",
            "#block-views-block-list-news-block"
          ).html()
        ) === "",
      $eventEmpty =
        $.trim(
          $(
            ".view-block-list-event",
            "#block-views-block-list-event-block"
          ).html()
        ) === "";

    if ($newEmpty) {
      $("#block-views-block-list-new-block").hide();

      if (!$eventEmpty) {
        $("#block-views-block-list-event-block")
          .removeClass("large-6")
          .find(".slick-slider")
          .slick("slickSetOption", "slidesToShow", 4, true);
      }
    }

    if ($eventEmpty) {
      $("#block-views-block-list-event-block").hide();

      if (!$newEmpty) {
        $("#block-views-block-list-news-block")
          .removeClass("large-6")
          .find(".slick-slider")
          .slick("slickSetOption", "slidesToShow", 4, true);
      }
    }
  }

  if ($("#block-views-block-list-news-event-block-1").length) {
    var $newEmpty =
        $.trim(
          $(
            ".view-block-list-news",
            "#block-views-block-list-news-block-1"
          ).html()
        ) === "",
      $eventEmpty =
        $.trim(
          $(
            ".view-block-list-event",
            "#block-views-block-list-event-block-1"
          ).html()
        ) === "";

    if ($newEmpty) {
      $("#block-views-block-list-new-block-1").hide();

      if (!$eventEmpty) {
        $("#block-views-block-list-event-block-1")
          .removeClass("large-6")
          .find(".slick-slider")
          .slick("slickSetOption", "slidesToShow", 4, true);
      }
    }

    if ($eventEmpty) {
      $("#block-views-block-list-event-block-1").hide();

      if (!$newEmpty) {
        $("#block-views-block-list-news-block-1")
          .removeClass("large-6")
          .find(".slick-slider")
          .slick("slickSetOption", "slidesToShow", 4, true);
      }
    }
  }

  if (
    $(".view-block-list-news.view-id-block_list_news.view-display-id-block_2")
      .length
  ) {
    $(".view-block-list-news.view-id-block_list_news.view-display-id-block_2")
      .removeClass("large-6")
      .find(".slick-slider")
      .slick("slickSetOption", "slidesToShow", 2, true);
    $(this).addClass("row");
    $(
      ".view-block-list-news.view-id-block_list_news.view-display-id-block_2 > div"
    ).addClass("large-6 columns");

    $("#block-views-block-list-news-block-2 > h2").prependTo(
      ".view-id-block_list_news.view-display-id-block_2 > .view-content"
    );

    if ($("#block-block-3").length) {
      $("#block-block-3").addClass("large-6 columns");
      $("#block-block-3").appendTo(
        ".view-id-block_list_news.view-display-id-block_2"
      );
    }
  }

  /* -- END SIZE NEWS AND EVENTS ----------------------------------------- */
});
